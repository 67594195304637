<section class="trust-section">
    <div class="images-wrapper">
        <img src="https://convertri.imgix.net/f4a386fb-7427-11ed-aeb6-06deec350f13%2F2c66e67d0671159ee80e14f1f0ff196b1536dc53%2Ftrustpilot-stars9095-removebg-preview.png?auto=compress,format&fit=scale&w=238&h=127" alt="Trustpilot" />
        <img src="https://convertri.imgix.net/f4a386fb-7427-11ed-aeb6-06deec350f13%2F0e36077489015b7bd3a851613e2f12d8626fc6e2%2F3.png%3Fauto%3Dcompress%2Cformat%26rect%3D143%2C0%2C122%2C115%26fit%3Dscale%26w%3D72%26h%3D68.png?auto=compress,format&fit=scale&w=265&h=115" alt="Norton" />
    </div>
    <p>
        <b>Disclaimer</b>: This is an advertorial and not an actual news article, blog, or consumer protection update.​ Our aim is to bring consumers the best information that may help them make informed decisions. This article is for demonstration purposes only. By proceeding forward you automatically agree to our terms and conditions. The purpose of this site is the solicitation of insurance. This article is for demonstration purposes only. By proceeding forward you automatically agree to our terms and conditions. The purpose of this site is the solicitation of insurance. This site is not a part of the Facebook website or Facebook Inc. This site is not endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc. $89/Month Average Expenditure
    </p>
</section>

<style>
    .trust-section {
        background-color: #eeeeee;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgb(33, 37, 41);
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: left;
        letter-spacing: 0em;
        padding-bottom: 60px;
    }

    .images-wrapper {
        display: flex;
        gap: 190px;
        margin: 66px 0 36px;
    }

    p {
        max-width: 1081px;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    @media (max-width: 700px) {
        .trust-section {
            padding-right: 20px;
            padding-left: 20px;
        } 

        .images-wrapper {
            gap: 20px;
        }
    }

    @media (max-width: 500px) {
        .images-wrapper {
            gap: 20px;
            flex-direction: column;
        }
    }
</style>