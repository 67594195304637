<script>
    const currentDate = new Date().toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    function getSubsidiesBasedOnTime() {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

        // @ts-ignore
        const proportionOfDayPassed = (now - startOfDay) / (endOfDay - startOfDay);
        const subsidiesGranted = Math.round(1235 + proportionOfDayPassed * (102759 - 1235));

        return subsidiesGranted.toLocaleString();
        }


        function updateHeaderCounts() {
        const subsidiesGranted = getSubsidiesBasedOnTime();
        document.getElementById('subsidies-granted').textContent = `${subsidiesGranted}`;
        }

        document.addEventListener('DOMContentLoaded', () => {
        updateHeaderCounts(); 
        setInterval(updateHeaderCounts, 15 * 60 * 1000);
        });
</script>

<header>
    <div>
        <p class="amount" id="subsidies-granted">93,509</p>
        <p class="text">Americans Saved on Auto Insurance TODAY</p>
        <p class="date">{currentDate}!</p>
    </div>
</header>

<style>
    header {
        position: sticky;
        top: 0;
        color: #ffffff;
        text-align: center;
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(47, 158, 233);
    }

    header div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 968px;
        background: linear-gradient(to right, #2F80ED, #56CCF2);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        height: 100%;
        padding: 24px;
    }

    .amount {
        background: rgba(255, 255, 255, 0.3);
        padding: 5px 5px;
        border-radius: 5px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .text {
        font-weight: 500;
    }

    .date {
        background: rgba(255, 255, 255, 0.3);
        padding: 1px 1px;
        border-radius: 5px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }
    
    p {
        font-size: 21px;
        font-weight: 700;
        font-style: italic;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    @media (max-width: 700px) {
        header div {
            width: 100%;
        }

        p {
            font-size: 23px;
        }
    }
</style>