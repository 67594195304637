<script>
  import Header from './components/header/Header.svelte'
  import TitleSection from './components/sections/TitleSection.svelte'
  import CtaSection from './components/sections/CtaSection.svelte'
  import TrustSection from './components/sections/TrustSection.svelte'
  import Footer from './components/footer/Footer.svelte'
</script>

<main>
  <Header />
  <TitleSection />
  <CtaSection />
  <TrustSection />
  <Footer />
</main>

