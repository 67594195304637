<footer>
    <a href="https://simplesaveusa.com/privacy-policy">
        <p>Privacy Policy</p>
    </a>
    |
    <a href="https://benefitsaversusa.com/terms">
        <p>Terms Of Use</p>
    </a>
</footer>

<style>
    footer {
        display: flex;
        justify-content: center;
        gap: 4px;
        padding: 30px 0 20px;
        background-color: #ffffff;
    }

    a {
        text-decoration: underline;
        color: black;
    }
    
    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
</style>