<script>
    export let text = "Text";  
    export let name = "Sophia";  
  </script>

<div class="card">
    <p>"{text}"</p>
    <div class="name">
        <p>"- {name}"</p>
    </div>
</div>

<style>
    .card {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #555555;
        font-weight: 400;
        font-size: 16px;
        font-style: italic;
        padding: 20px;
        width: 100%;
        height: 124px;
        box-sizing: border-box;
        line-height: normal;
        text-align: start;
    }

    .name {
        display: flex;
        justify-content: end;
        width: 100%;
        color: #000000;
        font-weight: 600;
    }

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
</style>