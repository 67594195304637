<section class="title-section">
    <div class="white-part">
        <p class="title-1">Unlock America’s Best Car Insurance Rates Today!</p>
        <!-- <p class="title-2">America</p> -->
        <p class="title-3">COMPARE AUTO INSURANCE NOW!</p>
    </div>
    <div class="black-part">
        <p class="title-4">Fast & Free Car Insurance Quotes</p>
        <p class="title-5">Instantly Compare Rates from Trusted Providers!</p>
    </div>
    <p class="title-6">GET A QUOTE IN AS LITTLE AS 60 SECONDS. YOU MAY BE ELIGIBLE FOR AS LOW AS $29.99/month</p>
</section>

<style>
    .title-section {
        background-color: rgb(47, 158, 233);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 38px;
        width: 100%;
    }

    .white-part {
        max-width: 904px;
        font-size: 52px;
        letter-spacing: 0;
        line-height: 60px;
        text-size-adjust: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-top: 20px;
        padding: 0 20px;
    }

    .black-part {
        color: rgb(13, 13, 13);
        max-width: 904px;
        font-size: 48px;
        letter-spacing: 0;
        line-height: 60px;
        text-size-adjust: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 22px;
        padding: 0 20px;
    }

    .title-2 {
        font-style: italic;
    }

    .title-5 {
        font-size: 28px;
        margin-bottom: 12px;
    }

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    @media (max-width: 700px) {
        .white-part {
            font-size: 36px;
            line-height: 41px;
            padding: 0 20px 12px;
        }

        .black-part {
            font-size: 24px;
            line-height: 40px;
            margin-top: 0;
            gap: 6px;
        }

        .title-5 {
            font-size: 21px;
            line-height: 35px;
        }

        .title-6 {
            font-size: 22px;
            line-height: 25px;
            padding: 0 20px;
        }

        .title-section {
            padding-bottom: 20px;
        }
    }
</style>